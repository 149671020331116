




























import Vue from 'vue';
import { INodeTypeDescription } from 'n8n-workflow';

import NodeIcon from '../NodeIcon.vue';
import TriggerIcon from '../TriggerIcon.vue';

Vue.component('NodeIcon', NodeIcon);
Vue.component('TriggerIcon', TriggerIcon);

export default Vue.extend({
	name: 'NodeItem',
	props: [
		'active',
		'filter',
		'nodeType',
		'bordered',
	],
	computed: {
		shortNodeType() {
			return this.$locale.shortNodeType(this.nodeType.name);
		},
	},
	// @ts-ignore
	isTrigger (nodeType: INodeTypeDescription): boolean {
		return nodeType.group.includes('trigger');
	},
});
