













































import Vue from 'vue';

export default Vue.extend({
	name: 'Banner',
	data() {
		return {
			expanded: false,
		};
	},
	props: {
		theme: {
			type: String,
			validator: (value: string): boolean =>
				['success', 'danger'].indexOf(value) !== -1,
		},
		message: {
			type: String,
		},
		buttonLabel: {
			type: String,
		},
		buttonLoadingLabel: {
			type: String,
		},
		buttonTitle: {
			type: String,
		},
		details: {
			type: String,
		},
		buttonLoading: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		expand() {
			this.expanded = true;
		},
		onClick() {
			this.expanded = false;
			this.$emit('click');
		},
	},
});
