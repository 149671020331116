





































import Vue from 'vue';


export default Vue.extend({
	name: 'AuthView',
	props: {
		form: {
		},
		formLoading: {
			type: Boolean,
			default: false,
		},
		subtitle: {
			type: String,
		},
	},
	methods: {
		onInput(e: {name: string, value: string}) {
			this.$emit('input', e);
		},
		onSubmit(values: {[key: string]: string}) {
			this.$emit('submit', values);
		},
		onSecondaryClick() {
			this.$emit('secondaryClick');
		},
	},
	computed: {
		baseUrl(): string {
			return this.$store.getters.getBaseUrl;
		},
	},
});
