
















































import mixins from "vue-typed-mixins";

import { showMessage } from "@/components/mixins/showMessage";
import Modal from "./Modal.vue";
import Vue from "vue";
import { IUser } from "../Interface";
import { mapGetters } from "vuex";
import { N8nUserSelect } from 'n8n-design-system';

export default mixins(showMessage).extend({
	components: {
		Modal,
		N8nUserSelect,
	},
	name: "DeleteUserModal",
	props: {
		modalName: {
			type: String,
		},
		activeId: {
			type: String,
		},
	},
	data() {
		return {
			modalBus: new Vue(),
			loading: false,
			operation: '',
			deleteConfirmText: '',
			transferId: '',
			ignoreIds: [this.activeId],
		};
	},
	computed: {
		...mapGetters('users', ['allUsers', 'currentUserId']),
		userToDelete(): IUser {
			const getUserById = this.$store.getters['users/getUserById'];
			return getUserById(this.activeId);
		},
		isPending(): boolean {
			return this.userToDelete && !this.userToDelete.firstName;
		},
		title(): string {
			const user = this.userToDelete && (this.userToDelete.fullName || this.userToDelete.email);
			return this.$locale.baseText('settings.users.deleteUser', { interpolate: { user }});
		},
		enabled(): boolean {
			if (this.isPending) {
				return true;
			}
			if (this.operation === 'delete' && this.deleteConfirmText === this.$locale.baseText('settings.users.deleteConfirmationText')) {
				return true;
			}

			if (this.operation === 'transfer' && this.transferId) {
				return true;
			}

			return false;
		},
	},
	methods: {
		setOperation(operation: string) {
			this.operation = operation;
			this.transferId = '';
		},
		setConfirmText(text: string) {
			this.deleteConfirmText = text;
		},
		setTransferId(id: string) {
			this.transferId = id;
		},
		async onSubmit() {
			try {
				if (!this.enabled) {
					return;
				}

				this.loading = true;

				const params = {id: this.activeId} as {id: string, transferId?: string};
				if (this.operation === 'transfer') {
					params.transferId = this.transferId;
				}

				await this.$store.dispatch('users/deleteUser', params);

				let message = '';
				if (this.transferId) {
					const getUserById = this.$store.getters['users/getUserById'];
					const transferUser: IUser = getUserById(this.transferId);
					message = this.$locale.baseText('settings.users.transferredToUser', { interpolate: { user: transferUser.fullName }});
				}

				this.$showMessage({
					type: 'success',
					title: this.$locale.baseText('settings.users.userDeleted'),
					message,
				});

				this.modalBus.$emit('close');

			} catch (error) {
				this.$showError(error, this.$locale.baseText('settings.users.userDeletedError'));
			}
			this.loading = false;
		},
	},
});

