








import AuthView from './AuthView.vue';
import { showMessage } from '@/components/mixins/showMessage';

import mixins from 'vue-typed-mixins';
import {IBot, IFormBoxConfig} from '@/Interface';
import { VIEWS } from '@/constants';

export default mixins(
	showMessage,
).extend({
	name: 'SigninView',
	components: {
		AuthView,
	},
	data() {
		const FORM_CONFIG: IFormBoxConfig = {
			title: this.$locale.baseText('auth.enterDetailsBelow'),
			buttonText: this.$locale.baseText('auth.signin'),
			inputs: [
				{
					name: 'email',
					properties: {
						label: this.$locale.baseText('auth.email'),
						type: 'email',
						required: true,
						validationRules: [{ name: 'VALID_EMAIL' }],
						showRequiredAsterisk: false,
						validateOnBlur: false,
						autocomplete: 'email',
						capitalize: true,
					},
				},
				{
					name: 'password',
					properties: {
						label: this.$locale.baseText('auth.password'),
						type: 'password',
						required: true,
						showRequiredAsterisk: false,
						validateOnBlur: false,
						autocomplete: 'current-password',
						capitalize: true,
					},
				},
			],
		};

		return {
			FORM_CONFIG,
			loading: false,
		};
	},
	methods: {
		async onSubmit(values: {[key: string]: string}) {
			try {
				this.loading = true;
				await this.$store.dispatch('users/loginWithCreds', values);
				this.clearAllStickyNotifications();
				this.loading = false;
				const botList: IBot[] = (await this.$store.dispatch('users/getBotListAction')).data;
				if (!botList.length) {
					this.$showToast({
						title: this.$locale.baseText('startupError'),
						message: "Do not have access for Bot module",
						type: 'error',
						duration: 5000,
					});
					try {

						await this.$store.dispatch('users/logout');
						document.cookie = "rl_trait" +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
						document.cookie = "rl_user_id" +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
					} catch (e) {
						this.$showError(e, this.$locale.baseText('auth.signout.error'));
					}
				} else {
					if (typeof this.$route.query.redirect === 'string') {
						const redirect = decodeURIComponent(this.$route.query.redirect);
						if (redirect.startsWith('/')) { // protect against phishing
							this.$router.push(redirect);

							return;
						}
					}
					this.$router.push({ name: VIEWS.HOMEPAGE });
				}
			} catch (error) {
				this.$showError(error, this.$locale.baseText('auth.signin.error'));
				this.loading = false;
			}
		},
	},
});
