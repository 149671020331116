





















import camelcase from 'lodash.camelcase';
import { INodeCreateElement } from '@/Interface';
import Vue from 'vue';

import ItemIterator from './ItemIterator.vue';

export default Vue.extend({
	name: 'SubcategoryPanel',
	components: {
		ItemIterator,
	},
	props: ['title', 'elements', 'activeIndex'],
	computed: {
		subcategoryName() {
			return camelcase(this.title);
		},
	},
	methods: {
		selected(element: INodeCreateElement) {
			this.$emit('selected', element);
		},
		onBackArrowClick() {
			this.$emit('close');
		},
	},
});
