
























































import { externalHooks } from '@/components/mixins/externalHooks';
import { ICredentialsResponse } from '@/Interface';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import { mapGetters } from "vuex";

import mixins from 'vue-typed-mixins';
import { convertToDisplayDate } from './helpers';
import { CREDENTIAL_SELECT_MODAL_KEY, CREDENTIAL_LIST_MODAL_KEY } from '@/constants';

import CredentialIcon from './CredentialIcon.vue';
import Modal from './Modal.vue';

export default mixins(
	externalHooks,
	genericHelpers,
	nodeHelpers,
	showMessage,
).extend({
	name: 'CredentialsList',
	components: {
		Modal,
		CredentialIcon,
	},
	data() {
		return {
			CREDENTIAL_LIST_MODAL_KEY,
			loading: true,
		};
	},
	computed: {
		...mapGetters('credentials', ['allCredentials']),
		credentialsToDisplay(): ICredentialsResponse[] {
			return this.allCredentials.reduce((accu: ICredentialsResponse[], cred: ICredentialsResponse) => {

				accu.push({
					...cred,
					createdAt: convertToDisplayDate(cred.createdAt as number),
					updatedAt: convertToDisplayDate(cred.updatedAt as number),
				});

				return accu;
			}, []);
		},
	},
	async mounted() {
		try {
			await Promise.all([
				await this.$store.dispatch('credentials/fetchCredentialTypes'),
				await this.$store.dispatch('credentials/fetchAllCredentials', { filter: { botId: this.$store.getters.getSelectedBot } }),
			]);
		}	catch (e) {
			this.$showError(e, this.$locale.baseText('credentialsList.errorLoadingCredentials'));
		}
		this.loading = false;

		this.$externalHooks().run('credentialsList.mounted');
		this.$telemetry.track('User opened Credentials panel', { workflow_id: this.$store.getters.workflowId });
	},
	destroyed() {
		this.$externalHooks().run('credentialsList.destroyed');
	},
	methods: {
		createCredential () {
			this.$store.dispatch('ui/openModal', CREDENTIAL_SELECT_MODAL_KEY);
		},

		editCredential (credential: ICredentialsResponse) {
			this.$store.dispatch('ui/openExisitngCredential', { id: credential.id});
			this.$telemetry.track('User opened Credential modal', { credential_type: credential.type, source: 'primary_menu', new_credential: false, workflow_id: this.$store.getters.workflowId });
		},

		async deleteCredential (credential: ICredentialsResponse) {
			const deleteConfirmed = await this.confirmMessage(
				this.$locale.baseText(
					'credentialsList.confirmMessage.message',
					{ interpolate: { credentialName: credential.name }},
				),
				this.$locale.baseText('credentialsList.confirmMessage.headline'),
				null,
				this.$locale.baseText('credentialsList.confirmMessage.confirmButtonText'),
				this.$locale.baseText('credentialsList.confirmMessage.cancelButtonText'),
			);

			if (deleteConfirmed === false) {
				return;
			}

			try {
				await this.$store.dispatch('credentials/deleteCredential', {id: credential.id});
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('credentialsList.showError.deleteCredential.title'),
				);

				return;
			}

			// Now that the credentials got removed check if any nodes used them
			this.updateNodesCredentialsIssues();

			this.$showMessage({
				title: this.$locale.baseText('credentialsList.showMessage.title'),
				type: 'success',
			});
		},
		stopPropagation(event: MouseEvent) {
			event.stopPropagation();
		},
		// getCredentialWithIcon(name: string) {
		// 	const type = this.$store.getters['credentials/getCredentialTypeByName'](name);
		// 	if (type.icon) {
		// 		return type;
		// 	}

		// 	if (type.extends) {
		// 		return type.extends.reduce((accu: string | null, type: string) => {
		// 			return accu || this.getCredentialWithIcon(type);
		// 		}, null);
		// 	}

		// 	return null;
		// },
	},
});
