
















































import Vue from 'vue';

import TemplateDetailsBlock from '@/components/TemplateDetailsBlock.vue';
import HoverableNodeIcon from '@/components/HoverableNodeIcon.vue';

import { abbreviateNumber, filterTemplateNodes } from '@/components/helpers';
import { ITemplatesNode } from '@/Interface';

export default Vue.extend({
	name: 'TemplateDetails',
	props: {
		blockTitle: {
			type: String,
		},
		loading: {
			type: Boolean,
		},
		template: {
			type: Object,
		},
	},
	components: {
		HoverableNodeIcon,
		TemplateDetailsBlock,
	},
	methods: {
		abbreviateNumber,
		filterTemplateNodes,
		redirectToCategory(id: string) {
			this.$store.commit('templates/resetSessionId');
			this.$router.push(`/templates?categories=${id}`);
		},
		redirectToSearchPage(node: ITemplatesNode) {
			this.$store.commit('templates/resetSessionId');
			this.$router.push(`/templates?search=${node.displayName}`);
		},
	},
});
