











import HoverableNodeIcon from '@/components/HoverableNodeIcon.vue';

import { genericHelpers } from '@/components/mixins/genericHelpers';
import { ITemplatesNode } from '@/Interface';

import mixins from 'vue-typed-mixins';
import { filterTemplateNodes } from './helpers';

export default mixins(genericHelpers).extend({
	name: 'NodeList',
	props: {
		nodes: {
			type: Array,
		},
		limit: {
			type: Number,
			default: 4,
		},
		size: {
			type: String,
			default: 'sm',
		},
	},
	components: {
		HoverableNodeIcon,
	},
	computed: {
		filteredCoreNodes() {
			return filterTemplateNodes(this.nodes as ITemplatesNode[]);
		},
		hiddenNodes(): number {
			return this.filteredCoreNodes.length - this.countNodesToBeSliced(this.filteredCoreNodes);
		},
		slicedNodes(): ITemplatesNode[] {
			return this.filteredCoreNodes.slice(0, this.countNodesToBeSliced(this.filteredCoreNodes));
		},
	},
	methods: {
		countNodesToBeSliced(nodes: ITemplatesNode[]): number {
			if (nodes.length > this.limit) {
				return this.limit - 1;
			} else {
				return this.limit;
			}
		},
	},
});
