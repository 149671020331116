





































import Vue from 'vue';
import {
	IVariableSelectorOption,
	IVariableItemSelected,
} from '@/Interface';

export default Vue.extend({
	name: 'VariableSelectorItem',
	props: [
		'allowParentSelect',
		'extendAll',
		'item',
	],
	computed: {
		itemAddOperations () {
			const returnOptions = [
				{
					command: 'raw',
					displayName: 'Raw value',
				},
			];
			if (this.item.dataType === 'array') {
				returnOptions.push({
					command: 'arrayLength',
					displayName: 'Length',
				});
				returnOptions.push({
					command: 'arrayValues',
					displayName: 'Values',
				});
			} else if (this.item.dataType === 'object') {
				returnOptions.push({
					command: 'objectKeys',
					displayName: 'Keys',
				});
				returnOptions.push({
					command: 'objectValues',
					displayName: 'Values',
				});
			}

			return returnOptions;
		},
	},
	data () {
		return {
			extended: false,
		};
	},
	methods: {
		optionSelected (command: string, item: IVariableSelectorOption) {
			// By default it is raw
			let variable = item.key;
			if (command === 'arrayValues') {
				variable = `${item.key}.join(', ')`;
			} else if (command === 'arrayLength') {
				variable = `${item.key}.length`;
			} else if (command === 'objectKeys') {
				variable = `Object.keys(${item.key}).join(', ')`;
			} else if (command === 'objectValues') {
				variable = `Object.values(${item.key}).join(', ')`;
			}
			this.$emit('itemSelected', { variable });
		},
		selectItem (item: IVariableSelectorOption) {
			this.$emit('itemSelected', { variable: item.key });
		},
		forwardItemSelected (eventData: IVariableItemSelected) {
			this.$emit('itemSelected', eventData);
		},
	},
});
