<template>
	<n8n-select v-model="selectedBot" size="small" @change="botChange">
		<n8n-option v-for="bot in botList" :key="bot.botName" :label="bot.botName" :value="bot._id" >
		</n8n-option>
	</n8n-select>
</template>

<script>
import mixins from 'vue-typed-mixins';
import { VIEWS } from '@/constants';

export default mixins()
	.extend({
		name: 'BotSelection',
		props:["botList"],
		data () {
			return {
				selectedBot: localStorage.getItem('selectedBot') || '',
			};
		},
		methods: {
			async botChange(id) {
				this.$store.commit('setSelectedBot', id);
				// Get Bot FI by bot id
				const botFI = await this.$store.dispatch('users/getBotFIAction', { botId: id });
				this.$store.commit('setBotFI', botFI);
				this.$emit("botChange");
			},
		},
	});
</script>

<style lang="scss">
	.side-menu-wrapper {
		.el-select {
			display: none;
			width: calc(100% - 120px );
    		margin-inline: auto;
			border-radius: 50px;
		}

		.el-select  {
			.el-input , .el-input.is-focus {
				 .el-input__inner {
    				border: 1px solid #CACFD5;
    				border-radius: 50px;
    				color: #444444;
    				font-weight: 400;
    				font-size: 14px;
    				line-height: 16px;
    				overflow: hidden;
    				padding:10px 45px 10px 20px;
    				text-overflow: ellipsis;
				}
			}
		}

		.el-input.el-input--small.el-input--suffix, .el-input--small .el-input__inner {
			height: 100%;
		}
		&.expanded {
			.el-select {
				display: inline-block;
			}
		}

		.el-input__suffix {
			right: 20px;
		}
	}
</style>